export interface StoryChoiceProps {
    label: string;
    onClick?: () => void;
}

function StoryChoice({
    label,
    onClick,
}: StoryChoiceProps) {
    return (
        <button className="choice-button" onClick={() => onClick?.()}>
            {label}
        </button>
    )
}

export default StoryChoice;