
// @ts-ignore
//import { Story2 } from "inkjs";
import { Story } from "../inkjs/engine/Story";
import { useEffect, useRef, useState } from "react";
import StoryFragment from "./StoryFragment";
import { Choice } from "../inkjs/engine/Choice";
import StoryChoice from "./StoryChoice";
import { StoryFragmentData } from "./models";

export interface StorytellerProps {
    inkStory: any;
}

function Storyteller ({
    inkStory,
}: StorytellerProps) {
    const choiceContainerRef = useRef<HTMLDivElement>(null);

    const [story] = useState(new Story(inkStory));

    const [fragments, setFragments] = useState([] as StoryFragmentData[]);
    const [choices, setChoices] = useState([] as Choice[]);

    useEffect(() => {
        continueStory();
    }, [story]);

    useEffect(() => {
        if (choiceContainerRef.current === null) return;

        choiceContainerRef.current.scrollIntoView({behavior: 'smooth'});
        //ref.current.scrollTo(0, ref.current.scrollWidth);
    }, [fragments.length]);

    return (
        <div className="story-container">
            <div className="fragment-container">
                {fragments.map((fr, i) => <StoryFragment
                    key={i}
                    data={fr}
                />)}
            </div>
            <div ref={choiceContainerRef} className="choice-list-container">
                {choices.map((choice, i) => <StoryChoice
                    key={i}
                    label={choice.text}
                    onClick={() => applyChoice(choice)}
                />)}
            </div>
        </div>
    );


    function continueStory() {
        let paras = [] as string[];

        while (story.canContinue) {
            const currentFrag = story.Continue();

            if (currentFrag === null) continue;

            paras.push(currentFrag);
        }

        if (paras.filter(p => p !== "").length > 0) {
            setFragments(prevState => [
                ...prevState,
                {
                    paragraphs: paras,
                }
            ]);
        }

        const currentChoices = story.currentChoices;
        setChoices(currentChoices);
    }

    function applyChoice (choice: Choice) {
        story.ChooseChoiceIndex(choice.index);
        continueStory();
    }
}

export default Storyteller;