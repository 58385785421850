import React from 'react';
//import logo from './logo.svg';

import historia from "./data/intercept.ink";
// @ts-ignore
//import { Story } from "inkjs";
//import { Story } from "inkjs/src/engine/Story";

import "./styles/main.scss";
import Storyteller from './ink/Storyteller';

function App() {

    return (
        <div className="App">
            <div className="storyteller-container">
                <Storyteller inkStory={historia} />
            </div>
        </div>
    );
}

export default App;
