import { useEffect, useRef, useState } from "react";
import { StoryFragmentData } from "./models";
import { PARAGRAPH_DELAY } from "../settings";

export interface StoryFragmentProps {
    data: StoryFragmentData;
    onFinishDisplay?: () => void;
}

function StoryFragment({
    data,
    onFinishDisplay,
}: StoryFragmentProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [showPara, setShowPara] = useState(data.paragraphs.map(p => false));

    useEffect(() => {
        for (let i = 0; i < data.paragraphs.length; i++) {
            const delay = i * (PARAGRAPH_DELAY * 1000);
            setTimeout(() => {
                setShowPara(prevState => {
                    const update = [...prevState];
                    update[i] = true;
                    return update;
                })
            }, delay);
        }
    }, [data.paragraphs.length]);

    return (
        <div className="story-fragment" ref={ref}>
            {data.paragraphs.map((p, i) => <p
                    key={i}
                    className={showPara[i] ? "visible" : "hidden"}
                >
                    {p}
                </p>
            )}
        </div>
    )
}

export default StoryFragment;